.text-primary {
  color: #f37417 !important;
}
.btn-primary {
  background-color: #f37417 !important;
  border-color: #f37417 !important;
}
.btn-primary:hover {
  background-color: #ca1300 !important;
  border-color: #ca1300 !important;
}
.shadow-md {
  box-shadow: 0 0.1rem 0.5rem rgba(0, 0, 0, 0.15) !important;
}
header {
  min-height: 92px;
  position: relative;
  z-index: 1000;
}
footer {
  background: #262626;
  border-top: solid 12px #373737;
}
footer ul {
  opacity: 0.7;
}
footer ul li i {
  opacity: 0.7;
}
.copyright {
  opacity: 0.7;
  border-top: solid 1px rgba(256, 256, 256, 0.1);
}
.light-border {
  border-right: solid 1px rgba(256, 256, 256, 0.1);
}
.page-banner-gradient a {
  color: #f8d568;
    text-decoration: none;
    background-color: transparent;
}

.page-sub-title:after {
  content: "";
  background: #fc7f0c;
  width: 80px;
  height: 2px;
  margin: 24px auto 0px auto;
  display: block;
}

.box-color-1 {
  background: #ca1300;
}
.box-color-2 {
  background: #54623e;
}
.box-color-3 {
  background: #428bca;
}
.box-color-4 {
  background: #69B340;
}
.box-color-1,
.box-color-2,
.box-color-3,
.box-color-4 {
  min-height: 285px;
}
.box-color-1 i,
.box-color-2 i,
.box-color-3 i,
.box-color-4 i {
  font-size: 48px;
}
.box-color-1 h5,
.box-color-2 h5,
.box-color-3 h5,
.box-color-4 h5 {
  font-size: 24px;
  border-bottom: solid 1px rgba(256, 256, 256, 0.2);
  margin-bottom: 16px;
  padding-bottom: 16px;
}

.main-banner {
  position: relative;
}
.main-banner .banner-slide-content {
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 1;
  width: 100%;
}
.main-banner h2 {
  color: #ffd54f;
}

.page-banner {
  position: relative;
  min-height: 180px;
  text-align: center;
}

.page-banner-gradient {  
  background: linear-gradient(to right, #6ab33f 0%, #19afa7 100%);
  color: #fff !important;
}
.page-banner-gradient i, .page-banner-gradient h1, .page-banner-gradient h2, .page-banner-gradient h3, .page-banner-gradient h4, .page-banner-gradient h5 {  
  color: #fff !important;
}

.page-banner-gradient .page-title:after {
  background: none;
}
.page-banner-gradient .module-title:after {
  background: #fff;
}
.HajjUmrahBooking-crs-tabs {
  float: left;
  width: 100%;
  border-bottom: solid 2px rgba(0, 0, 0, 0.1);
  padding: 0px;
  position: relative;
  margin-top: 30px;
  z-index: 1000;
}
.carousel__inner-slide img {
  width: 100% !important;
}
.carousel__slide {
  padding-bottom: 17% !important;
}
.carousel{
  width: 41%;
}

.B2C .carousel{
  margin-top: 100px;
}
.B2B .carousel{
  margin-top: 100px;
}
.BackOffice .carousel{
  margin-top: 25px;
}
.carousel__inner-slide img {
  cursor: pointer;
}
.HajjUmrahBooking-crs-tabs h3 {
  float: left;
  padding: 8px 24px;
  margin: 0px 8px 0px 0px;
  font-size: 16px;
  line-height: 32px;
  text-align: left;
  font-weight: 600;
  position: relative;
  margin-bottom: -2px;
  cursor: pointer;
  color: #666;
  background: #f1f1f1;
}
button:disabled {
  opacity: 0.5;
cursor: not-allowed;
}
.HajjUmrahBooking-crs-tabs h3.active, .HajjUmrahBooking-crs-tabs h3:hover {
  background: #385E4D;
  border-bottom: solid 2px #385E4D;
  color: #fff;
}
.HajjUmrahBooking-crs .tab-details {
  position: relative;
  float: left;
  width: 100%;
}
.tab-details img {
  cursor: pointer;
}
.carousel__next-button, .carousel__back-button {
  background: none;
  border: none;
  margin-top: 20px;
}
.carousel {
  text-align: center;
}
.page-title:after {
  content: "";
  background: #fc7f0c;
  width: 120px;
  height: 4px;
  margin: 24px auto 0px auto;
  display: block;
}
.module-title {
  text-align: center;
}
.HajjUmrahBooking-crs .tab-details figure {
  float: left;
  width: 40%;
  padding: 0px;
  margin: 0px;
  position: relative;
  right: 0px;
  top: 15px;
  border-radius: 8px;
  padding: 10px;
}
.HajjUmrahBooking-crs ul {
  float: left;
  padding: 0px 50px 0px 25px;
  margin: 20px 0px 0px 0px;
  list-style: none;
  width: 60%;
}
.module-title:after {
  content: "";
  background: #fc7f0c;
  width: 120px;
  height: 4px;
  margin: 24px auto 0px auto;
  display: block;
}
.gammaGreen-text{  
  color: #385E4D !important;
}
.d-flex.pt-2.rounded.align-items-top i {
  float: left;
  padding-right: 12px;
  padding-top: 5px;
  color: #385E4D !important;
  font-size: 22px;
  height: 32px;
}
.umrahOneImg{
  width: 110%;
  margin-left: -20px;
}
.d-flex.pt-2.rounded.align-items-top.white i {
  float: left;
  padding-right: 12px;
  padding-top: 5px;
  color: #fff !important;
  font-size: 22px;
  height: 32px;
}
.border-top {
  border-top: 1px solid #dee2e6!important;
}
.border-bottom {
  border-bottom: 1px solid #dee2e6!important;
}
.umrah-module-title:after {
  content: "";
  background: #000;
  width: 120px;
  height: 4px;
  margin: 24px auto 0px auto;
  display: block;
}
.page-banner-img {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  z-index: -2;
  background-position: center center;
}
.page-banner-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  background: rgba(0, 0, 0, 0.6);
  z-index: -1;
}

.navbar ul li {
  position: relative;
}

.navbar ul li a {
  position: relative;
  text-decoration: none;
  padding: 12px 8px;
  display: block;
}

.navbar ul li ul {
  display: none;
  background: rgb(18, 70, 128);
  z-index: 1;
  position: absolute;
  left: 24px;
  top: 48px;
  min-width: 240px;
  border-radius: 4px;
  padding: 8px 0px 4px 0px;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.4);
}

.navbar ul li ul::before {
  position: absolute;
  top: -6px;
  left: 20px;
  content: "";
  width: 0px;
  height: 0px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid rgb(18, 70, 128);
  font-size: 0px;
  line-height: 0px;
}

.navbar ul li:hover ul {
  display: inherit;
}

.navbar ul li ul li a {
  color: #fff;
  padding: 8px 12px;
  display: block;
  border-bottom: solid 1px rgba(256, 256, 256, 0.3);
}

.navbar ul li ul li a:hover {
  background: #fc7f0c;
}

.navbar ul li ul li:last-child a {
  border-bottom: 0px none;
}

@media (max-width: 575px) {
  .page-title h1 {
    font-size: 1.8rem;
  }
  .page-title h5 {
    font-size: 1rem;
  }
  .inner-page ul {
    text-align: left;
  }
  .inner-page img {
    width: 100%;
  }

  .main-banner .banner-slide-content ul {
    display: none;
  }
  .main-banner h2 {
    font-size: 1.6rem;
  }
  .main-banner h6 {
    font-size: 0.9rem;
    border-bottom: none !important ;
  }
  .main-banner a {
    display: none;
  }
  .main-banner article {
    padding: 8px 0px 0px 0px !important;
    width: 70% !important;
  }

  .box-color-1,
  .box-color-2,
  .box-color-3 {
    min-height: unset;
    margin-bottom: 8px;
  }

  .page-sub-title {
    font-size: 1.8rem;
  }

  header .navbar {
    display: none;
  }
  .carousel {
    width: 100%;
    padding-left: 30px;
}
.HajjUmrahBooking-crs-tabs h3 {
  float: left;
  padding: 8px 11px;
  margin: 0px 4px 0px 0px;
}

.umrahOneImg{
  
  width: 50% !important;
  margin: 0px 25%;
}
.umrahFeature {
 margin-left: 0px !important;
}
.umrahFeatureImg {
  padding: 10px !important;
}
.umrahSolutionOverview {
  margin: 10px !important;
}
}
